import axios from 'axios'
import store from '@/store'
import {LOGOUT} from "@/modules/auth/store/actions/AuthActions";

const mainApiUrl = process.env.VUE_APP_MAIN_API_URL
const ilmApiUrl = process.env.VUE_APP_ILM_API_URL

let mainApi = axios.create({ baseURL: mainApiUrl, withCredentials:true })
let loginApi = axios.create({ baseURL: mainApiUrl, withCredentials:true })
let ilmApi = axios.create({ baseURL: ilmApiUrl, withCredentials:true })

let authInterceptor = function(request) {
  request.headers['Authorization'] = `Bearer ${store.getters.getToken}`
  return request
}
let tokenResponseInterceptor = (response) => {
  return response;
}
let tokenErrorInterceptor = (error) => {
  if (error.response.status == 401) {
    return store.dispatch(LOGOUT)
  } else {
    return Promise.reject(error);
  }
}

mainApi.interceptors.response.use(tokenResponseInterceptor, tokenErrorInterceptor);
mainApi.interceptors.request.use(authInterceptor, (error) => Promise.reject(error))

ilmApi.interceptors.response.use(tokenResponseInterceptor, tokenErrorInterceptor);
ilmApi.interceptors.request.use(authInterceptor, (error) => Promise.reject(error))

export default {
  mainApi: mainApi,
  loginApi: loginApi,
  ilmApi: ilmApi
}
